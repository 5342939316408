import React from 'react';
import ReactDOM from 'react-dom';
import './scss/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from "./redux/store"
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Modal from 'react-modal';

//import { makeServer } from "./server"

if (process.env.NODE_ENV === "development") {
  //makeServer({ environment: "development" })
}

Sentry.init({
  dsn: "https://facca14399804db0bb4d8b1ed920a8ad@o556459.ingest.sentry.io/5909614",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.01,
});

Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
